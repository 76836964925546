import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';
import { useId } from 'react';
import { extractInstanceIndexFromId } from '../../utils';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { mapMultimediaListToVideoProps } from '@dx-ui/osc-video-player';
import { HorizontalTabbedSection } from './HorizontalTabbedSection';
import { VerticalTabbedSection } from './VerticalTabbedSection';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { HamptonHexVisual } from './HamptonHexVisual';

export default createCpmComponentDefinition(
  'Tabbed Section',

  function mapData({ data, mappedPage }) {
    const { link, id, label, headline, shortDescription, longDescription, logoUrl, iconType } =
      data;
    const videoData = data.videos?.[0];
    const multimediaList = data.multimedia;
    const asset = data.cpmAssets[0];
    const imageUrl = asset?.aspectRatios?.['3x2']?.url;
    const captionData = asset
      ? {
          captionLink: asset.captionLink,
          caption: asset.caption,
        }
      : undefined;
    const video = videoData
      ? {
          source: videoData.source || '',
          title: videoData?.name || '',
          url: videoData.url,
        }
      : undefined;
    const multimedia = multimediaList?.length
      ? mapMultimediaListToVideoProps({ multimedia: multimediaList, mappedPage })
      : undefined;
    const hasImage = imageUrl && asset?.altText;
    const hasVideo = multimedia?.videoUrl || video?.url;

    return {
      id,
      links: data.links,
      ...(logoUrl && { logoUrl }),
      ...(iconType && { iconType }),
      tab: {
        label,
      },
      panel: {
        media: {
          multimedia,
          video,
        },
        image:
          hasImage && !hasVideo
            ? {
                alt: asset?.altText || '',
                url: imageUrl ?? '',
                captionData,
              }
            : undefined,
        headline,
        copy: shortDescription,
        longDescription: longDescription || undefined,
        link: {
          label: link?.label ?? '',
          url: link?.url ?? '',
          adaDescription: link?.adaDescription ?? '',
          isNewWindow: link?.isNewWindow,
        },
      },
    };
  },

  function TabbedSection({
    items = [],
    listData: data,
    componentParams,
    metrics,
    mappedPage: { brandCode, localeCode },
  }) {
    const id = useId();
    const createManageContentButton = useCreateManageContentButton();
    const { tabbedSectionDisplay } = componentParams;

    if (!items.length) {
      return null;
    }

    const filteredItems = items.map((item) => {
      return {
        ...item,
        cmsDocumentControl: createManageContentButton(item?.$ref),
        panel: {
          ...item.panel,
          // Replace link with one that has been filtered by experimentation agent. Links are filtered by the CPM SDK.
          // The link will be the same one as the mapping function if no experimentation agent(s) have been defined.
          link: item.links?.[0] ?? { url: '', label: '' },
        },
      };
    });

    const onTabChange = (tabId: string) => {
      if (metrics && tabId) {
        const targetTabIndex = filteredItems.findIndex((item) => item.id === tabId);
        const label = filteredItems?.[targetTabIndex]?.tab?.label ?? '';
        metrics.trackTabItemClick?.([
          {
            label,
            tabComponentNumber: extractInstanceIndexFromId(id),
            itemNumber: targetTabIndex + 1,
            totalItems: filteredItems.length,
          },
        ]);
      }
    };

    return (
      <BrandComponentThemeInline
        brandCode={brandCode}
        componentParams={componentParams}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <HamptonHexVisual
          theme={componentParams.theme ?? ''}
          enabled={brandCode === 'HP' && componentParams.backgroundIllustration === 'hx-hexagon'}
        >
          <AnimateRevealItem delay={0} animationType="fade-in-up" isAnimated={brandCode === 'GU'}>
            {tabbedSectionDisplay === 'vertical' ? (
              <VerticalTabbedSection
                textAlign={componentParams.listTextAlign}
                listDescription={data?.description ?? undefined}
                listHeadline={data?.headline ?? undefined}
                items={filteredItems}
                brandComponentTheme={componentParams.theme}
                id={id}
              />
            ) : (
              <HorizontalTabbedSection
                textAlign={componentParams.listTextAlign}
                listDescription={data?.description ?? undefined}
                listHeadline={data?.headline ?? undefined}
                id={id}
                items={filteredItems}
                defaultTab={filteredItems[0]?.id}
                onTabChange={onTabChange}
                brandComponentTheme={componentParams.theme}
                localeCode={localeCode}
              />
            )}
          </AnimateRevealItem>
        </HamptonHexVisual>
      </BrandComponentThemeInline>
    );
  }
);
