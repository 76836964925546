/**
 * Ensures the cue has text content.
 */
export function isValidCue(cue: VTTCue) {
  return cue.text.trim().length > 0;
}

/**
 * Generates a unique cue id.
 */
export function getCueId(cue: VTTCue) {
  return ['cue', cue.id, crypto.randomUUID()].filter(Boolean).join('-');
}

/**
 * Parses the VTTCue text and returns the text content.
 */
export function getCueText(cue: VTTCue) {
  try {
    return new XMLSerializer().serializeToString(cue.getCueAsHTML());
  } catch {
    return cue.text;
  }
}

/**
 * The smallest buffer to add to the cue startTime when adjusting the
 * video currentTime due to precision mismatch related to activeCues.
 * https://stackoverflow.com/questions/55777551/how-do-i-avoid-floating-point-rounding-errors-in-webvtt
 */
export const CUE_BUFFER = 0.00001;
