import { createCpmComponentDefinition, useCreateManageContentButton } from '@dx-ui/cpm-sdk';
import { ImageHeadliner, ImageHeadlinerItem } from '@dx-ui/osc-marketing';
import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';

export default createCpmComponentDefinition(
  'Image Headliner',

  function mapData({ data }) {
    const { headline, link } = data;

    const asset = data.cpmAssets?.[0];

    const imageUrl = asset?.aspectRatios['3x2']?.url;
    const imageAltText = asset?.altText;

    return {
      headline,
      id: `ImageHeadlinerItem-${data.id}`,
      imageAltText,
      imageUrl,
      link: link?.url ? link : undefined,
      links: data.links,
    };
  },

  function CpmImageHeadliner({ items = [], componentParams, mappedPage: { brandCode } }) {
    const createManageContentButton = useCreateManageContentButton();

    if (!items.length) {
      return null;
    }

    const itemsWithManageContentButton = items.map((item) => ({
      ...item,
      cmsDocumentControl: createManageContentButton(item.$ref),
    }));

    return (
      <BrandComponentThemeInline componentParams={componentParams} brandCode={brandCode}>
        <ImageHeadliner brandComponentTheme={componentParams?.theme}>
          {itemsWithManageContentButton.map((props, index) => (
            <ImageHeadlinerItem
              key={props.id}
              headline={props.headline}
              id={props.id}
              imageAltText={props.imageAltText}
              imageUrl={props.imageUrl}
              link={props.link}
              index={index}
              brandComponentTheme={componentParams?.theme}
              cmsDocumentControl={props.cmsDocumentControl}
              cmsTranslationClasses={props.cmsTranslationClasses}
            />
          ))}
        </ImageHeadliner>
      </BrandComponentThemeInline>
    );
  }
);
