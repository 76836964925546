import {
  ANALYTICS_NO_VALUE,
  type BaseImageMetrics,
  ImageClickID,
  type ImageClickIDType,
  ImageFunction,
  ImageType,
} from '@dx-ui/config-metrics';
import type { CarouselSingleMetrics } from '../types/carousel-analytics';

type GenerateCarouselSingleMetricsParams = {
  metrics?: Partial<BaseImageMetrics>;
  position?: number;
  images?: { alt: string }[];
  itemTitle?: string;
  clickID: ImageClickIDType;
  imageFunction?: (typeof ImageFunction)['Link'];
};

/**
 * Utility function to generate carousel metrics
 */
export function generateCarouselSingleMetrics({
  metrics,
  position,
  images,
  itemTitle = ANALYTICS_NO_VALUE,
  clickID = ImageClickID.Scroll,
  imageFunction,
}: GenerateCarouselSingleMetricsParams): CarouselSingleMetrics {
  const carouselMetrics = {
    click: {
      ...metrics?.click,
      clickID,
    },
    osc: {
      ...metrics?.osc,
      image: {
        ...metrics?.osc?.image,
        headline: metrics?.osc?.image?.headline || ANALYTICS_NO_VALUE,
        count: images?.length || 0,
        position: position || 0,
        itemTitle: itemTitle || ANALYTICS_NO_VALUE,
        type: ImageType.Carousel,
      },
    },
  };

  if (imageFunction === ImageFunction.Link) {
    carouselMetrics.osc.image = {
      ...carouselMetrics.osc.image,
      function: ImageFunction.Link,
    };
  }

  return carouselMetrics;
}

/**
 * Utility function to generate CPM carousel metrics
 * The returned object will serve as a partial image metric object to be extended within the CarouselSingle component
 */
export function generateCpmCarouselMetrics(
  headline: string | null | undefined
): Partial<BaseImageMetrics> {
  return {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        // All the other CarouselSingleMetrics attributes will be set in CarouselSingle
        headline: headline || ANALYTICS_NO_VALUE,
        type: ImageType.Carousel,
      },
    },
  };
}
