import { useState } from 'react';
import type { AspectRatio, TResponsiveImage } from '@dx-ui/osc-responsive-image';
import { useTranslation } from 'next-i18next';
import { GalleryImage } from './gallery-image';
import cx from 'classnames';

export type GalleryItem = {
  id: string;
  image: {
    alt: string;
    aspectRatio: AspectRatio;
    url: string;
    captionData: TResponsiveImage['captionData'];
  };
  /** Used for the tabbed gallery to group items based on the category (category is a tab name) */
  category?: string;
};

export type GalleryItems = Array<GalleryItem>;

export function Gallery({
  items,
  isAnimated = false,
  brandComponentTheme,
}: {
  items: GalleryItems;
  isAnimated?: boolean;
  brandComponentTheme?: CmsBrandComponentTheme;
}) {
  const { t } = useTranslation('osc-gallery');

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const [isGalleryExpanded, setIsGalleryExpanded] = useState(false);
  const galleryItemsLength = items?.length || 0;

  const showViewBtn = (galleryItemsLength && galleryItemsLength > 12) || false;

  return (
    <>
      <ul className="grid grid-cols-1 gap-3 sm:grid-cols-2 xl:grid-cols-3">
        {items.map((item, idx) => {
          const shouldHideImage = idx + 1 > 12 && !isGalleryExpanded;
          if (!shouldHideImage) {
            return (
              <li key={item.id}>
                <GalleryImage
                  item={item}
                  idx={idx}
                  isAnimated={isAnimated}
                  items={items}
                  dialogTitle={
                    item.category ? t('categoryGallery', { category: item.category }) : t('gallery')
                  }
                />
              </li>
            );
          }
          return null;
        })}
      </ul>
      {showViewBtn ? (
        <div className="flex justify-center py-6">
          <button
            onClick={() => setIsGalleryExpanded((isGalleryExpanded) => !isGalleryExpanded)}
            type="button"
            className={cx('btn btn-primary-link btn-xl text-text', {
              'text-text-inverse': isDark,
              'brand-ht:text-text-inverse': isLight,
            })}
          >
            {isGalleryExpanded ? t('viewLess') : t('viewAll', { count: galleryItemsLength })}
          </button>
        </div>
      ) : null}
    </>
  );
}

export default Gallery;
