import { ResponsiveImage, BREAKPOINTS } from '@dx-ui/osc-responsive-image';
import { useRef, useState } from 'react';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { GalleryItem } from './gallery';
import { AnimateRevealItem } from '@dx-ui/osc-animate-reveal-item';
import { useMediaQuery } from 'usehooks-ts';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { CarouselSingle } from '@dx-ui/osc-carousel';
import { useTranslation } from 'next-i18next';

type GalleryImageProps = {
  /** Details of the Gallery Image */
  item: GalleryItem;
  /** Index of the current image */
  idx: number;
  /** Indicates if the Gallery Image is animated */
  isAnimated?: boolean;
  /** All of the items in the gallery */
  items: Array<GalleryItem>;
  /** Title for a Dialog modal */
  dialogTitle: string;
};

export function GalleryImage({ item, items, idx, isAnimated, dialogTitle }: GalleryImageProps) {
  const ref = useRef<React.ElementRef<'button'>>(null);
  const rect = useRect({ ref });
  const { t } = useTranslation('osc-gallery');

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS['sm']}px)`);
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS['xl']}px)`);

  const numberOfItemsInARow = isDesktop ? 3 : isMobile ? 1 : 2;

  return (
    <AnimateRevealItem
      animationType="fade-in-up"
      delay={100 + (idx % numberOfItemsInARow) * 100}
      isAnimated={isAnimated}
      className="size-full"
    >
      <Dialog
        dialogTrigger={
          <button
            className="size-full"
            aria-label={t('focusedGalleryItem', {
              altText: item.image.alt,
            })}
            type="button"
            ref={ref}
            onClick={() => setIsDialogOpen(true)}
          >
            <ResponsiveImage
              aspectRatio={item.image.aspectRatio}
              altText={item.image.alt}
              imageUrl={item.image.url}
              width={rect?.width ?? 0}
              wrapperClassName="h-full"
              wrapperTag="span"
            />
          </button>
        }
        size="4xl"
        title={dialogTitle}
        onDismiss={() => setIsDialogOpen(false)}
        isOpen={isDialogOpen}
      >
        <CarouselSingle
          startingIndex={idx}
          images={items.map((item) => ({
            alt: item.image.alt,
            url: item.image.url,
            captionData: item.image.captionData,
          }))}
        />
      </Dialog>
    </AnimateRevealItem>
  );
}
