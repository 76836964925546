import { ANALYTICS_NO_VALUE, ImageType, ImageFunction, ImageClickID } from '@dx-ui/config-metrics';
import type { CollageMetrics } from '../types/collage-analytics';

export const COLLAGE_ANALYTICS_COUNT = 2; // Number of images in the collage

type GenerateCollageMetricsParams = {
  headline: string;
  itemTitle?: string;
  position: number;
};

export const generateCollageMetrics = ({
  headline = ANALYTICS_NO_VALUE,
  itemTitle = ANALYTICS_NO_VALUE,
  position = 0,
}: GenerateCollageMetricsParams): CollageMetrics => {
  const CollageMetrics: CollageMetrics = {
    click: {
      clickID: ImageClickID.Img,
    },
    osc: {
      image: {
        headline,
        itemTitle,
        position,
        count: COLLAGE_ANALYTICS_COUNT,
        type: ImageType.Collage,
        imageFunction: ImageFunction.Link,
      },
    },
  };

  return CollageMetrics;
};
