import { Markdown } from '@dx-ui/osc-markdown';
import { useRouter } from 'next/router';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';
import type { BrandPageComponentThemeType } from '@dx-ui/gql-types';
import type { ReactNode } from 'react';
import type { ContentBlock } from 'dx-shared-schema/dist/types/couchbase/story';
import {
  type OscDomLink,
  type ExperimentationAgents,
  shouldRenderExperiment,
} from '@dx-ui/cpm-sdk';

export function ContentWrapper({
  children,
  theme,
}: {
  children: ReactNode;
  theme: BrandPageComponentThemeType | undefined;
}) {
  const isDark = theme === 'dark';
  const isLight = theme === 'light';

  return (
    <div
      className={cx('border-border border-b p-6', {
        'text-text-inverse': isDark,
        'brand-ou:border-primary brand-ht:text-text-inverse': isLight,
      })}
    >
      {children}
    </div>
  );
}

export type ContentData = ContentBlock & {
  key: string;
  links?: OscDomLink[];
};

export function Content({
  content,
  experimentationAgents,
}: {
  content: ContentData;
  experimentationAgents: ExperimentationAgents;
}) {
  const { locale = 'en' } = useRouter();
  const origin = typeof window !== 'undefined' ? window.location.origin : '';
  const markdownProps = { language: locale, origin };

  if ('description' in content) {
    return <Markdown {...markdownProps}>{content.description!}</Markdown>;
  }

  if ('heading' in content) {
    return (
      <div className="mb-2 text-xl font-bold">
        <Markdown {...markdownProps}>{content.heading!}</Markdown>
      </div>
    );
  }

  if ('unorderedList' in content) {
    return Array.isArray(content.unorderedList) ? (
      <ul className="ms-7 list-disc">
        {content.unorderedList.map((listItem) => (
          <li key={listItem}>
            <Markdown {...markdownProps}>{listItem}</Markdown>
          </li>
        ))}
      </ul>
    ) : null;
  }

  if ('orderedList' in content) {
    return Array.isArray(content.orderedList) ? (
      <ol className="ms-7 list-decimal">
        {content.orderedList.map((listItem) => (
          <li key={listItem}>
            <Markdown {...markdownProps}>{listItem}</Markdown>
          </li>
        ))}
      </ol>
    ) : null;
  }

  if ('links' in content) {
    return Array.isArray(content.links)
      ? content.links
          .filter((link) =>
            shouldRenderExperiment(experimentationAgents, link.experimentationConfiguration)
          )
          .map((link) => (
            <div key={link.url + link.label}>
              <Link
                url={link.url}
                adaDescription={link.adaDescription}
                isNewWindow={link.isNewWindow}
                data-conductrics-goal={link.experimentationConfiguration?.goal}
                data-conductrics-value={link.experimentationConfiguration?.value}
              >
                {link.label}
              </Link>
            </div>
          ))
      : null;
  }

  return null;
}
