import * as React from 'react';
import { ImageHeadlinerContent } from './image-headliner-content';
import { ImageHeadlinerControls } from './image-headliner-controls';
import cx from 'classnames';
import type { Pagination } from '@dx-ui/osc-pagination';
import { Cycle } from '@dx-ui/osc-cycle';
import { ImageHeadlinerItem } from './image-headliner-item';
import { ANALYTICS_NO_VALUE } from '@dx-ui/config-metrics';

export type TImageHeadlinerItemData = {
  headline: string;
  ctaText: string;
  position: number;
};

export type TImageHeadliner = Pick<Pagination, 'label' | 'loop' | 'hideLabel' | 'current'> &
  React.HTMLAttributes<HTMLDivElement>;

export type ImageHeadlinerType = {
  label?: string;
  className?: string;
  /** Determines brand color theme for component */
  brandComponentTheme?: CmsBrandComponentTheme;
} & TImageHeadliner;

/**
 * Used to grab a user's attention with a bold background color and text. Alerts users to promotions, new hotels for humans or supernatural beings, important legal updates.
 * Contains an image/logo, headline text and a link to more information.
 */
export const ImageHeadliner: React.FC<ImageHeadlinerType> = ({
  current,
  className = '',
  hideLabel,
  loop,
  label,
  children,
  brandComponentTheme,
  id = 'image-headliner-cycle-id',
  ...rest
}) => {
  const validSlides = React.Children.map(
    children,
    (child) => React.isValidElement(child) && child.type === ImageHeadlinerItem && child
  )?.filter(Boolean) as unknown as (typeof ImageHeadlinerItem)[];

  const slides = React.Children.map(
    validSlides,
    (child, index) =>
      React.isValidElement(child) &&
      React.cloneElement(
        child as React.ReactElement<React.ComponentProps<typeof ImageHeadlinerItem>>,
        {
          index,
          totalSlides: validSlides.length,
        }
      )
  );
  const totalSlides = React.Children.count(slides);

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const itemData =
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child) && child.type === ImageHeadlinerItem) {
        // Extract props for analytics
        const { headline, link } = child.props || {
          headline: ANALYTICS_NO_VALUE,
          link: {
            label: ANALYTICS_NO_VALUE,
          },
        };
        return {
          headline,
          ctaText: link?.label,
          position: index + 1,
        } as TImageHeadlinerItemData;
      }
      return null;
    })?.filter(Boolean) || [];

  return (
    <Cycle id={id} current={current} total={totalSlides}>
      <div
        className={cx(className, 'relative flex w-full flex-col items-center py-2 sm:flex-row', {
          'bg-bg-dark': isDark,
          'bg-bg-light': isLight,
          'image-headliner-bg brand-hi-refresh:bg-[#009CDE]': !isLight && !isDark,
        })}
        data-testid="imageHeadliner"
      >
        <ImageHeadlinerContent {...rest}>{slides}</ImageHeadlinerContent>
        <ImageHeadlinerControls
          brandComponentTheme={brandComponentTheme}
          label={label}
          hideLabel={hideLabel}
          hasLoop={loop}
          itemData={itemData}
        />
      </div>
    </Cycle>
  );
};

export default ImageHeadliner;
