import type * as React from 'react';

import { TextBlock } from '@dx-ui/osc-text-block';
import type { ExperimentationConfiguration, OscDomLink } from '@dx-ui/cpm-sdk';
import { createCpmComponentDefinition } from '@dx-ui/cpm-sdk';

import BrandComponentThemeInline from '../../components/BrandComponentThemeInline';
import { MarkdownBlock } from './MarkdownBlock';

export type ParsedContentSection = Exclude<
  React.ComponentProps<typeof TextBlock>['data'],
  undefined
>[number];

export default createCpmComponentDefinition(
  'Text Component',

  function mapData({ data, cmsDocumentType, addIssue, clearIssue }) {
    if (data.markdownEditor) {
      return {
        experimentationConfiguration: data.experimentationConfiguration,
        links: data.links,
        markdown: data.markdownEditor,
      } as {
        markdown: string;
        experimentationConfiguration?: ExperimentationConfiguration;
        links: OscDomLink[];
      };
    }

    const includeLinks = cmsDocumentType === 'Story';

    const mappedContentBlocks: Array<ParsedContentSection | null> = (data.contentBlock ?? []).map(
      (section): ParsedContentSection | null => {
        if (section?.description) {
          return { kind: 'description', content: section.description };
        }
        if (section?.heading) {
          return { kind: 'heading', content: section.heading };
        }
        if (section?.orderedList) {
          return { kind: 'orderedList', content: section.orderedList };
        }
        if (section?.unorderedList) {
          return { kind: 'unorderedList', content: section.unorderedList };
        }

        return null;
      }
    );

    if (includeLinks && data?.links?.length) {
      const link = data.links[0];

      if (link) {
        mappedContentBlocks.push({
          kind: 'cta',
          content: {
            _id: '',
            url: link.url,
            label: link.label,
            adaDescription: link.adaDescription || '',
            isNewWindow: link.isNewWindow || false,
            experimentationConfiguration: link.experimentationConfiguration,
          },
        });
      }
    }

    const blocks = mappedContentBlocks.filter((section): section is ParsedContentSection =>
      Boolean(section)
    );

    if (!blocks.length) {
      addIssue({
        id: data.id,
        message: `"${data.name || data.displayName}" has no content attached`,
      });
    } else {
      clearIssue(data.id);
    }

    return {
      links: data.links,
      blocks,
    };
  },

  function TextBlockCpm({ items = [], componentParams, mappedPage: { brandCode } }) {
    const { textAlign, animation, theme, borderTrim } = componentParams;

    const data = items[0];

    if (!data) {
      return null;
    }

    if ('blocks' in data) {
      if (!data?.blocks.length) {
        return null;
      }

      const filteredBlocks = data.blocks.map((block) => {
        // Replace link with one that has been filtered by experimentation agent. Links are filtered by the CPM SDK.
        // The link will be the same one as the mapping function if no experimentation agent(s) have been defined.
        if (block.kind === 'cta' && data.links?.[0]) {
          block.content = data.links[0];
        }

        return block;
      });

      return (
        <BrandComponentThemeInline
          componentParams={componentParams}
          brandCode={brandCode}
          backgroundIllustration={{
            isParallax: componentParams?.backgroundParallax,
            variant: componentParams?.backgroundIllustration,
          }}
        >
          <TextBlock
            textAlign={textAlign}
            isAnimated={animation || brandCode === 'GU'}
            isBorderTrim={borderTrim}
            data={filteredBlocks}
            brandComponentTheme={theme}
          />
        </BrandComponentThemeInline>
      );
    }

    return (
      <BrandComponentThemeInline
        componentParams={componentParams}
        brandCode={brandCode}
        backgroundIllustration={{
          isParallax: componentParams?.backgroundParallax,
          variant: componentParams?.backgroundIllustration,
        }}
      >
        <MarkdownBlock
          textAlign={textAlign}
          isAnimated={animation || brandCode === 'GU'}
          isBorderTrim={borderTrim}
          markdown={data.markdown}
          brandComponentTheme={theme}
        />
      </BrandComponentThemeInline>
    );
  }
);
