import type { MouseEvent } from 'react';
import { forwardRef } from 'react';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import { BrandIcon } from './brand-icon';
import { getClassnames } from './util/button';
import type { Icon } from '@dx-ui/osc-icon';
import type { Variant } from './util/button';

export interface BrandLinkProps extends Omit<Link, 'label'> {
  label: React.ReactNode;
  /** Add Icon props to buttons with Icons */
  iconProps?: React.ComponentProps<typeof Icon>;
  /** Brand color themes, "light" | "dark" */
  brandComponentTheme?: CmsBrandComponentTheme;
  /** Button variant */
  variant?: Variant;
  /** OnClick callback */
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

export const BrandLink = forwardRef<HTMLAnchorElement, BrandLinkProps>(
  ({ label, iconProps, brandComponentTheme, variant, onClick, ...linkProps }, ref) => (
    <Link
      {...linkProps}
      ref={ref}
      anchorClassName={cx(
        linkProps.anchorClassName,
        getClassnames({ brandComponentTheme, variant })
      )}
      className={linkProps.className || ''}
      isNewWindow={linkProps.isNewWindow || false}
      url={linkProps.url}
      showNewWindowIcon={(linkProps.showNewWindowIcon && linkProps.isNewWindow) || false}
      underline={false}
      data-testid="CTALinkE2ETest"
      onClick={onClick}
    >
      {iconProps ? <BrandIcon {...iconProps} /> : null}
      <span>{label}</span>
    </Link>
  )
);

BrandLink.displayName = 'BrandLink';

export default BrandLink;
