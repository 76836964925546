import { useState } from 'react';
import {
  TabListScrollable,
  TabListButton,
  TabPanel,
  TabPanels,
  Tabs,
  sanitizeId,
} from '@dx-ui/osc-tabs';
import { isRtl } from '@dx-ui/utilities-get-language-direction';
import type { GalleryItems } from './gallery';
import cx from 'classnames';
import { Gallery } from './gallery';

export function TabbedGallery({
  items,
  isAnimated = false,
  localeCode,
  brandComponentTheme,
}: {
  items: GalleryItems;
  isAnimated?: boolean;
  /** language code */
  localeCode?: string;
  brandComponentTheme?: CmsBrandComponentTheme;
}) {
  const defaultActive = sanitizeId(items[0]?.category);
  const [activeCategoryId, setActiveCategoryId] = useState(defaultActive);
  const [focusedCategoryId, setFocusedCategoryId] = useState<undefined | string>(undefined);

  const categories = items
    .map((item) => {
      return item.category;
    })
    .filter((item) => {
      return !!item;
    });

  const uniqueCategories = Array.from(new Set(categories));

  const categoryItems = items.filter((i) => sanitizeId(i.category) === activeCategoryId);

  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <div>
      <Tabs
        changeWithArrow
        lang={localeCode}
        useUpDownArrows
        onTabChange={(id) => {
          setActiveCategoryId(id);
        }}
        defaultActive={defaultActive}
      >
        <TabListScrollable
          lang={localeCode}
          className={cx('scrollbar-hide mb-4 flex flex-row flex-nowrap overflow-x-scroll', {
            'horizontal-tabbed-list-scrollable-dark': isDark,
            'horizontal-tabbed-list-scrollable-light': isLight,
            'horizontal-tabbed-list-scrollable': !isDark && !isLight,
          })}
          leftArrowWrapperClassName={{
            'horizontal-tabbed-arrow-wrapper-dark': isDark,
            'horizontal-tabbed-arrow-wrapper-light': isLight,
            'horizontal-tabbed-arrow-wrapper': !isDark && !isLight,
          }}
          rightArrowWrapperClassName={{
            'horizontal-tabbed-arrow-wrapper-dark': isDark,
            'horizontal-tabbed-arrow-wrapper-light': isLight,
            'horizontal-tabbed-arrow-wrapper': !isDark && !isLight,
          }}
        >
          {uniqueCategories.map((category, index) => {
            const isFocusedTab = category === focusedCategoryId;
            const isRightBeforeFocusedtab =
              uniqueCategories[isRtl(localeCode) ? index - 1 : index + 1] === focusedCategoryId;
            const id = sanitizeId(category);

            return (
              <TabListButton
                onFocus={() => setFocusedCategoryId(category)}
                onBlur={() => setFocusedCategoryId(undefined)}
                id={id}
                key={category}
                activeClassName={cx('is-selected selected group z-10', {
                  'horizontal-tabbed-list-button-active-dark': isDark,
                  'horizontal-tabbed-list-button-active-light': isLight,
                  'horizontal-tabbed-list-button-active': !isDark && !isLight,
                })}
                className={cx(
                  'relative grow cursor-pointer appearance-none border-b-4 bg-transparent px-4 py-2 md:whitespace-normal',
                  {
                    'after:mb-2 after:absolute after:top-0 after:right-0 after:bottom-0 after:w-[1px] after:bg-text after:pointer-events-none last:after:hidden rtl:after:hidden':
                      !isFocusedTab && !isRightBeforeFocusedtab,
                    'rtl:before:mb-2 rtl:before:absolute rtl:before:top-0 rtl:before:right-0 rtl:before:bottom-0 rtl:before:w-[1px] rtl:before:bg-text rtl:before:pointer-events-none rtl:first:before:hidden':
                      !isFocusedTab && !isRightBeforeFocusedtab,
                    'min-w-[50%] lg:min-w-[20%]': uniqueCategories.length > 5,
                    'min-w-[50%] lg:min-w-fit': uniqueCategories.length <= 5,
                    'after:!gallery-tabs-divider-light rtl:before:!gallery-tabs-divider-light':
                      isLight,
                    'after:bg-text-inverse rtl:before:bg-text-inverse': isDark,
                  }
                )}
              >
                <span
                  className={cx(
                    'group m-auto flex min-w-[120px] flex-col items-center text-lg',
                    'brand-es:font-headline',
                    'brand-ey:font-normal',
                    'brand-hp:font-serif brand-hp:shrink-0',
                    'brand-nd:uppercase brand-nd:font-mono',
                    'brand-wa:uppercase brand-wa:font-sans brand-wa:font-light',
                    {
                      'hover:text-primary-alt focus:text-primary-alt brand-dt:hover:text-brand brand-dt:focus:text-brand brand-es:hover:text-primary brand-es:focus:text-primary':
                        id !== activeCategoryId,
                      'font-bold brand-nd:font-medium ': id === activeCategoryId,
                      'font-medium brand-gu:font-bold': id !== activeCategoryId,
                      'text-text-inverse': isDark,
                      'hover:text-text-inverse brand-es:hover:text-bg-light brand-es:focus:text-bg-light brand-nd:hover:!text-text-disabled brand-ey:hover:!text-brand-alt':
                        isDark && id !== activeCategoryId,
                      'brand-ht:!text-text-inverse brand-hp:text-primary': isLight,
                      'brand-nd:hover:text-primary-alt brand-wa:hover:text-bg-dark brand-ey:hover:text-primary-alt':
                        !isDark && id !== activeCategoryId,
                      'text-text brand-ey:text-primary brand-gu:text-primary brand-ou:text-primary brand-hp:text-primary brand-hp:hover:text-primary-alt':
                        !isDark,
                    }
                  )}
                >
                  {category}
                </span>
              </TabListButton>
            );
          })}
        </TabListScrollable>
        <TabPanels>
          {uniqueCategories.map((category) => {
            return (
              <TabPanel id={sanitizeId(category)} key={category}>
                <Gallery
                  isAnimated={isAnimated}
                  items={categoryItems}
                  brandComponentTheme={brandComponentTheme}
                />
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default TabbedGallery;
