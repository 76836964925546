import type * as React from 'react';
import { useRef } from 'react';
import cx from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import type { VideoPlayerProps } from '@dx-ui/osc-video-player';
import {
  Video,
  VideoCaptions,
  VideoControls,
  VideoPlayerMarkup,
  VideoTranscript,
  useVideoPlayer,
} from '@dx-ui/osc-video-player';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import type { Link as LinkType } from '@dx-ui/osc-link';
import Image from 'next/image';
import { getIsReducedMotion } from '@dx-ui/utilities-accessibility';

type VideoType = React.ComponentProps<typeof Video>;

export type HeroVideoType = VideoPlayerProps &
  Pick<VideoType, 'posterImageUrl'> & {
    videoElement?: VideoType['videoElement'];
    /**
     * CPM ONLY: Dark color theme or Light color theme.
     */
    brandComponentTheme?: CmsBrandComponentTheme;
    /**
     * Text content box alignment, center is default
     */
    alignContent?: CmsAlignContent;
    /**
     * Main headline of component
     */
    headline?: string;
    /**
     * Similar to a subheadline
     */
    shortDescription?: string;
    /**
     * Provides the long description
     */
    longDescription?: string | null;
    /**
     * OSC-Link
     */
    link?: (LinkType & { experimentationConfiguration?: CmsExperimentationConfiguration }) | null;
    /**
     * Allows animations to run when true, CMS option, default 'false'
     */
    isAnimated?: boolean;
    /**
     * Used to add or remove Horizontal Line between content, default 'false'
     */
    hasHorizontalLine?: boolean;
    /**
     * Small image icon in the upper left corner
     */
    badgeImageUrl?: string | null;
    /**
     * Small image icon alt text
     */
    badgeImageAltText: string;
    /**
     * Caption data
     */
    videoCaptionData?: VideoType['captionData'];
    /**
     * Markup schema
     */
    markupSchemas?: React.ComponentProps<typeof VideoPlayerMarkup>['markupSchemas'];
    /**
     * Space for a brand specific illustration overlay component
     */
    illustrationOverlay?: React.ReactNode;
    /**
     * Only for loops and cinemagraphs
     */
    altText?: string;
  };

/**
 * **Formerly Masthead with Video**
 *
 * The HeroVideo is a full screen width video from Cloudinary with video controls, optional added content, option badge icon, and optional gallery button.
 */
export const HeroVideo: React.FC<HeroVideoType> = ({
  brandComponentTheme,
  headline,
  link,
  shortDescription,
  longDescription,
  alignContent = 'center',
  isAnimated = false,
  hasHorizontalLine = false,
  badgeImageAltText,
  badgeImageUrl,
  videoUrl,
  videoGroup,
  videoLabel,
  videoName,
  posterImageUrl,
  isAutoPlay = true,
  videoCaptionData,
  audioTracks,
  captionTracks,
  transcriptTracks,
  markupSchemas,
  illustrationOverlay,
  altText,
}) => {
  const wrapperElement = useRef<React.ElementRef<'div'>>(null);
  const { ref: intersectionRef, isIntersecting: inView } = useIntersectionObserver({
    threshold: [0.15],
    freezeOnceVisible: true,
  });
  const attachedContentAnimate = !getIsReducedMotion() && isAnimated ? intersectionRef : null;
  const { videoProps, videoCaptionProps, videoControlsProps, videoTranscriptProps } =
    useVideoPlayer({
      audioTracks,
      captionTracks,
      isAutoPlay,
      transcriptTracks,
      videoGroup,
      videoLabel,
      videoName,
      videoUrl,
      wrapperElement,
    });
  const hasActiveTranscript = Boolean(videoTranscriptProps?.activeTranscript);
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  const isCenter = alignContent === 'center';
  const isLeft = alignContent === 'left';
  const isRight = alignContent === 'right';

  return (
    <div
      ref={wrapperElement}
      data-testid="heroVideoContainer"
      className={cx(
        'brand-nd:bg-bg brand-ou:bg-bg-light @container/hero-video brand-gu:bg-quarternary relative block w-full overflow-hidden',
        {
          'bg-bg brand-ey:bg-bg-light brand-qq:bg-bg-light brand-ou:bg-secondary': isDark,
          'bg-bg-light brand-hi-refresh:bg-bg brand-lx:bg-bg brand-ht:bg-bg brand-ou:bg-secondary':
            isLight,
        }
      )}
    >
      {markupSchemas ? <VideoPlayerMarkup markupSchemas={markupSchemas} /> : null}
      <div className="relative">
        <Video
          videoUrl={videoUrl}
          posterImageUrl={posterImageUrl}
          videoId="hero-video"
          captionData={videoCaptionData}
          isAutoPlay={isAutoPlay}
          role={altText ? 'img' : undefined}
          aria-label={altText ? altText : undefined}
          {...videoProps}
        />
        <VideoCaptions
          {...videoCaptionProps}
          className={cx(
            'absolute bottom-8 left-1/2 z-10 mx-auto w-[calc(100%-2rem)] -translate-x-1/2 text-center',
            {
              '@[640px]/hero-video:bottom-28 @[1280px]/hero-video:bottom-40 @[640px]/hero-video:w-full @[640px]/hero-video:max-w-48 @[1024px]/hero-video:max-w-lg @[640px]/hero-video:mb-1':
                !isRight,
              '@[640px]/hero-video:bottom-44 @[1280px]/hero-video:bottom-56 @[640px]/hero-video:max-w-fit':
                isRight,
            }
          )}
          brandComponentTheme={brandComponentTheme}
        />
      </div>
      <div className="flex">
        {badgeImageUrl ? (
          <div className="absolute top-8 ms-5 aspect-square h-24 sm:h-44 xl:ms-10 xl:h-52">
            <Image className="object-contain" src={badgeImageUrl} alt={badgeImageAltText} fill />
          </div>
        ) : null}
      </div>

      {headline || shortDescription || longDescription || link ? (
        <div className="container relative z-10 pb-6 xl:pb-12">
          <div className="@[640px]/hero-video:-mt-40 @[1280px]/hero-video:-mt-52 relative -mt-6">
            <div
              className={cx('sm:flex', {
                'sm:max-w-xl 2xl:max-w-2xl sm:ms-auto': isRight && !hasActiveTranscript,
              })}
            >
              <VideoControls
                buttonOptions={altText ? { mute: { isVisible: false } } : undefined}
                videoVariant={!!altText ? 'cinemagraph' : undefined}
                {...videoControlsProps}
                className="@[640px]/hero-video:mb-5 mb-2 flex justify-center"
                brandComponentTheme={brandComponentTheme}
              />
            </div>
            <VideoTranscript
              className="mb-5 shadow-lg"
              {...videoTranscriptProps}
              brandComponentTheme={brandComponentTheme}
            />
            <div
              data-testid="heroVideoTextBoxContainer"
              ref={attachedContentAnimate}
              className={cx(
                'bg-bg image-corner-radius mt-0 px-6 py-8 shadow-lg sm:px-12 xl:px-16 xl:py-12',
                {
                  'brand-ey:bg-bg-light brand-gu:bg-quarternary brand-ou:bg-secondary':
                    !isDark && !isLight,
                  'bg-bg-dark': isDark,
                  'bg-bg-light brand-ey:bg-bg brand-es:bg-bg': isLight,
                  'sm:max-w-xl 2xl:max-w-2xl': isRight || isLeft,
                  'ms-auto': isRight,
                }
              )}
            >
              {headline ? (
                <BrandTextHeader
                  className={cx(
                    'heading-4xl sm:heading-5xl whitespace-normal text-center delay-150',
                    {
                      'sm:text-left lg:heading-5xl': isLeft || isRight,
                      'sm:text-center lg:heading-6xl': isCenter,
                      '!text-text-inverse': isDark,
                      'brand-ht:text-text-inverse': isLight,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                      'pb-2': hasHorizontalLine && !shortDescription,
                    }
                  )}
                >
                  {headline}
                </BrandTextHeader>
              ) : null}

              {shortDescription ? (
                <BrandTextBody
                  className={cx(
                    'text-text-alt brand-qq:!text-sm brand-qq:2xl:!text-base brand-qq:tracking-[.25em] brand-qq:uppercase pb-3 text-center font-sans !text-xl font-bold delay-300',
                    {
                      'sm:text-left': isLeft || isRight,
                      'sm:text-center': isCenter,
                      'text-text-inverse': isDark,
                      'brand-ht:text-text-inverse': isLight,
                      'brand-es:text-primary brand-gu:text-primary brand-lx:text-primary brand-nd:text-primary brand-ou:text-primary brand-qq:text-primary':
                        !isDark,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                    }
                  )}
                  brandComponentTheme={brandComponentTheme}
                >
                  {shortDescription}
                </BrandTextBody>
              ) : null}

              {hasHorizontalLine ? (
                <hr
                  className={cx(
                    'border-text-alt brand-qq:border-t-2 brand-qq:border-[#cccccc] translate-y-2 border-t pb-6',
                    {
                      'border-text-inverse brand-qq:border-text-inverse brand-ou:border-secondary':
                        isDark,
                      'brand-ht:border-text-inverse': isLight,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                    }
                  )}
                />
              ) : null}

              {longDescription ? (
                <BrandTextBody
                  className={cx(
                    'text-text brand-qq:text-primary brand-qq:!text-base brand-qq:2xl:!text-xl whitespace-normal pb-5 text-center !text-xl font-normal tracking-normal sm:pb-6 xl:pb-8',
                    {
                      'sm:text-left': isLeft || isRight,
                      'sm:text-center': isCenter,
                      'text-text-inverse brand-qq:text-text-inverse': isDark,
                      'brand-ht:text-text-inverse': isLight,
                      'opacity-100 translate-y-0': inView && isAnimated,
                      'translate-y-4': !inView && isAnimated,
                      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                        isAnimated,
                    }
                  )}
                  brandComponentTheme={brandComponentTheme}
                >
                  {longDescription}
                </BrandTextBody>
              ) : null}

              {link ? (
                <div
                  className={cx('p-0 text-center delay-700', {
                    'sm:text-left': isLeft || isRight,
                    'sm:text-center': isCenter,
                    'opacity-100 translate-y-0': inView && isAnimated,
                    'translate-y-4': !inView && isAnimated,
                    'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
                      isAnimated,
                  })}
                >
                  <BrandLink
                    label={link.label}
                    isNewWindow={link.isNewWindow}
                    showNewWindowIcon={link.isNewWindow}
                    url={link.url}
                    brandComponentTheme={brandComponentTheme}
                    data-conductrics-goal={link.experimentationConfiguration?.goal}
                    data-conductrics-value={link.experimentationConfiguration?.value}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      {illustrationOverlay}
    </div>
  );
};

export default HeroVideo;
