import type { ReactNode } from 'react';
import Head from 'next/head';
import { useRef } from 'react';
import { BackToTop } from '@dx-ui/osc-back-to-top';
import { CustomHead } from '../custom-head';
import type * as Types from '@dx-ui/gql-types';

type TSocialMediaLinks = Array<Pick<Types.BrandSocialMediaLink, 'channel' | 'url'>>;
type TBrandsQuery =
  | (Pick<Types.Brand, 'code' | 'name' | 'formalName' | 'url' | 'locationsUrl'> & {
      socialMediaLinks: TSocialMediaLinks;
      phone: Pick<Types.BrandPhone, 'number' | 'friendlyNumber'>;
    })
  | undefined;

export const Layout = ({
  children,
  top,
  assetPath,
  brandSvgPath,
  bottom,
  brandCode,
  localeCode,
  baseAppUrl,
  fullBrandPath,
  brandData,
  seo,
  articleTitle,
  seoImage,
  appVersion,
  ohwBaseUrl,
  shouldIncludeBackToTopButton,
  isBrandHomePage,
  supportedLanguages,
}: {
  children: ReactNode;
  top?: ReactNode;
  bottom?: ReactNode;
  brandCode: string;
  localeCode: string;
  baseAppUrl: string;
  fullBrandPath: string;
  articleTitle: string;
  seoImage: string;
  seo: {
    hideSearchEngine: boolean;
    metaDescription: string | null;
    metaKeywords: string | null;
    pageHeading: string | null;
    pageTitle: string | null;
  };
  brandData?: TBrandsQuery;
  appVersion: string;
  ohwBaseUrl: string;
  shouldIncludeBackToTopButton: boolean;
  supportedLanguages?: string[];
  assetPath: string;
  brandSvgPath: string;
  isBrandHomePage: boolean;
}) => {
  const mainRef = useRef<HTMLElement | null>(null);

  const favIconPath = `${assetPath}/img/brand/${brandCode}/favicon_${brandCode}`;

  return (
    <>
      <CustomHead
        pageTitle={seo?.pageTitle ?? ''}
        metaDescription={seo?.metaDescription ?? undefined}
        metaKeywords={seo?.metaKeywords ?? undefined}
        brandLogoImageSrc={brandSvgPath}
        hideSearchEngine={seo?.hideSearchEngine ?? undefined}
        schemaName={brandData?.formalName ?? undefined}
        schemaUrl={brandData?.url ?? undefined}
        baseAppUrl={baseAppUrl}
        localeCode={localeCode}
        seoImage={seoImage}
        seoArticleTitle={fullBrandPath.includes('/articles/') ? articleTitle : undefined}
        brandCode={brandData?.code}
        socialMediaLinks={brandData?.socialMediaLinks}
        phoneNumber={brandData?.phone?.number}
        fullBrandPath={fullBrandPath}
        appVersion={appVersion}
        ohwBaseUrl={ohwBaseUrl}
        supportedLanguages={supportedLanguages}
        isBrandHomePage={isBrandHomePage}
      />
      <Head>
        <link
          rel="icon"
          href={`${favIconPath}.svg`}
          type="image/svg+xml"
          sizes="any"
          media="(prefers-color-scheme: light)"
        />
        <link
          rel="icon"
          href={`${favIconPath}-dark.svg`}
          type="image/svg+xml"
          sizes="any"
          media="(prefers-color-scheme: dark)"
        />
        <link
          rel="icon"
          href={`${favIconPath}.png`}
          type="image/png"
          media="(prefers-color-scheme: light)"
        />
        <link
          rel="icon"
          href={`${favIconPath}-dark.png`}
          type="image/png"
          media="(prefers-color-scheme: dark)"
        />
      </Head>

      <div className="isolate m-0 w-full p-0">
        <div className="z-2">{top}</div>
        <div className="z-1 relative flex flex-col">
          <main ref={mainRef}>
            <div>{children}</div>
            {shouldIncludeBackToTopButton ? <BackToTop mainRef={mainRef} /> : null}
          </main>
          {bottom}
        </div>
      </div>
    </>
  );
};
