import type { Document } from '@bloomreach/spa-sdk';
import type {
  BrandNavigationListData,
  BrComponent,
  BrPage,
  CmsDomLink,
  ExtractCampaignCodeTaggingValuesFn,
  ListData,
  Models,
  NavigationListData,
} from '../adapters/types';
import type {
  CpmListData,
  CpmMappedComponentData,
  CpmMappingFunction,
  CpmMappingFunctionInput,
  ExperimentationAgentIdsSet,
  CpmMandatoryMappingOutputFields,
  OutputWithMandatories,
} from './types';
import type { CmsDataTypesIntersection, MappedComponentName, MappingSchema } from '../schema';
import { mappingSchema } from '../schema';
import type { useIssues } from '../hooks/use-issues';
import {
  getDocumentKeyNumbersMap,
  getDocumentOfTypeFromPageAndComponent,
  getFromPageByRef,
  modelsToRefs,
} from '../adapters/getters';
import { makeCmsComponentDocumentEnhancer } from '../transformers/enhancers';
import type { CpmMappedPage } from './cpmMappedPage';
import { parseComponentParams } from './parseComponentParams';
import { createAddCampaignCodeToUrl } from '../utils/add-campaign-id-to-url';
import { removeNullyFromArray } from '../utils/remove-nully-from-array';
import { registerExperimentItem } from '../utils/register-experiment-item';
import { transformLink, translateNavigationListLinks } from '../transformers/transform-links';

function handleError<T>(
  fallback: T,
  fn: () => T,
  isCPMEditor: boolean,
  errorAccumulator?: Array<Error>
): T {
  const isLiveMode = !isCPMEditor;

  try {
    return fn();
  } catch (err) {
    if (isLiveMode) {
      return fallback;
    } else {
      if (errorAccumulator && err instanceof Error) {
        errorAccumulator.push(err);
        return fallback;
      } else {
        throw err;
      }
    }
  }
}

function getItemsRefs(listData: ListData | null, componentBr: BrComponent) {
  const models = componentBr.getModels<Models>();
  const directRefs = modelsToRefs(models);
  const { itemsRef } = listData ?? { itemsRef: directRefs };

  return removeNullyFromArray(itemsRef);
}

function isItemUntranslated(item: unknown) {
  return (
    item &&
    typeof item === 'object' &&
    'cmsTranslationClasses' in item &&
    typeof item.cmsTranslationClasses === 'string'
  );
}

export function applyMapData<
  Component extends MappedComponentName,
  Output extends CpmMandatoryMappingOutputFields
>(
  mapData: CpmMappingFunction<Component, Output>,
  input: CpmMappingFunctionInput<Component>
): OutputWithMandatories<Output> {
  const mappedData = mapData(input);

  return {
    ...mappedData,
    cmsTranslationClasses: input.data.cmsTranslationClasses,
    $ref: input.data.ref?.$ref ?? '',
    segmentIds: input.data.segmentIds,
    experimentationConfiguration: input.data.experimentationConfiguration,
  };
}

export function mapCpmData<
  Component extends MappedComponentName,
  Output extends CpmMandatoryMappingOutputFields
>(
  component: Component,
  mapData: CpmMappingFunction<Component, Output>,
  pageBr: BrPage,
  componentBr: BrComponent,
  mappedPage: CpmMappedPage,
  { add: addIssue, clear: clearIssue }: ReturnType<typeof useIssues>,
  extractCampaignCodeTaggingValues: ExtractCampaignCodeTaggingValuesFn,
  isCPMEditor = false
): CpmMappedComponentData<Component, Output> {
  type ComponentSchema = MappingSchema[Component];

  const pageType: string = pageBr.getDocument<Document>()?.getData().type;
  const componentName: string = componentBr.getName() ?? '';

  const componentSchema: ComponentSchema = mappingSchema[component];
  const componentParams = parseComponentParams(
    componentSchema,
    componentBr.getParameters<Partial<Record<string, string | boolean>>>()
  );

  const addCampaignCodeToUrl = createAddCampaignCodeToUrl(
    componentName,
    mappedPage,
    extractCampaignCodeTaggingValues
  );
  const enhanceData = makeCmsComponentDocumentEnhancer<ComponentSchema>(
    componentSchema,
    pageBr,
    addCampaignCodeToUrl
  );

  const experimentationAgentIds: ExperimentationAgentIdsSet = new Set();

  registerExperimentItem(experimentationAgentIds, isCPMEditor, componentParams);

  switch (componentSchema.mappingKind) {
    case 'Unmapped': {
      return {
        componentParams,
        pageType,
        componentName,
        mappedPage,
        isCPMEditor,
        experimentationAgentIds,
        mappingKind: componentSchema.mappingKind,
        maintainUnsegmentedCount: false,
      } as unknown as CpmMappedComponentData<Component, Output>;
    }

    case 'NavigationListDocument': {
      const cmsData =
        getDocumentOfTypeFromPageAndComponent<NavigationListData | BrandNavigationListData>(
          ['NavigationList', 'BrandNavigationList'],
          componentBr,
          pageBr
        )?.[0] ?? null;

      return handleError<CpmMappedComponentData<Component, Output>>(
        {
          componentParams,
          pageType,
          componentName,
          navigationList: null,
          mappedPage,
          isCPMEditor,
        } as unknown as CpmMappedComponentData<Component, Output>,
        () => {
          if (cmsData === null || !cmsData.items) {
            throw new Error('can not useCpmData when useData is null');
          }

          const cmsDocumentType = cmsData.contentType.replace('dxcms:', '');

          if (!(componentSchema.cmsDocumentTypes as readonly string[]).includes(cmsDocumentType)) {
            throw new Error(`${componentName} can not render "${cmsDocumentType}" documents`);
          }

          const { items } = translateNavigationListLinks(cmsData.items, addCampaignCodeToUrl);

          items?.forEach((item) => {
            if ('primaryNav' in item) {
              registerExperimentItem(
                experimentationAgentIds,
                isCPMEditor,
                item.primaryNav.experimentationConfiguration
              );

              item.secondaryNavItems.forEach((item) => {
                registerExperimentItem(
                  experimentationAgentIds,
                  isCPMEditor,
                  item.experimentationConfiguration
                );
              });
            }

            if ('links' in item) {
              item.links.forEach((link) => {
                registerExperimentItem(
                  experimentationAgentIds,
                  isCPMEditor,
                  link.experimentationConfiguration
                );
              });
            }
          });

          const navigationList = applyMapData(mapData, {
            data: {
              ...cmsData,
              items,
            },
            mappedPage,
            componentParams,
            pageType,
            componentName,
            addIssue,
            clearIssue,
            pageBr,
            componentBr,
            cmsDocumentType,
            isCPMEditor,
            addCampaignCodeToUrl,
          } as CpmMappingFunctionInput<Component>);

          registerExperimentItem(
            experimentationAgentIds,
            isCPMEditor,
            navigationList.experimentationConfiguration
          );

          return {
            componentParams,
            pageType,
            componentName,
            navigationList,
            mappedPage,
            experimentationAgentIds,
            mappingKind: componentSchema.mappingKind,
            maintainUnsegmentedCount: componentSchema.maintainUnsegmentedCount,
          } as unknown as CpmMappedComponentData<Component, Output>;
        },
        isCPMEditor
      );
    }

    case 'ListDocument': {
      const listData =
        getDocumentOfTypeFromPageAndComponent<ListData>(
          ['ListDocument'],
          componentBr,
          pageBr
        )?.[0] ?? null;

      let cpmListData: CpmListData | null = null;

      if (listData) {
        const links = removeNullyFromArray(
          listData.links.map((link: CmsDomLink) => transformLink(link, addCampaignCodeToUrl))
        );

        links.forEach((link) => {
          registerExperimentItem(
            experimentationAgentIds,
            isCPMEditor,
            link.experimentationConfiguration
          );
        });

        cpmListData = {
          ...listData,
          links,
        };
      }

      return handleError<CpmMappedComponentData<Component, Output>>(
        {
          componentParams,
          pageType,
          componentName,
          listData,
          mappedPage,
          isCPMEditor,
        } as unknown as CpmMappedComponentData<Component, Output>,
        () => {
          //We want to be able to run multiple mappings, and get the errors for each of them.
          //rather than just throwing on the first error that occurs
          const errorAccumulator: Array<Error> = [];
          const itemRefs = getItemsRefs(listData, componentBr);

          // Get document numbers for inline list docs, so we can exclude them from translation
          const documentNumbers = getDocumentKeyNumbersMap(componentBr.getModels());

          const keyedItems = itemRefs
            .map((ref) => getFromPageByRef<CmsDataTypesIntersection>(ref, pageBr))
            .filter((x: CmsDataTypesIntersection | null): x is CmsDataTypesIntersection => !!x)
            .map((item, index, { length }): Output | null =>
              handleError(
                null,
                () => {
                  const cmsDocumentType = item.contentType.replace('dxcms:', '');

                  if (
                    !(componentSchema.cmsDocumentTypes as readonly string[]).includes(
                      cmsDocumentType
                    )
                  ) {
                    throw new Error(
                      `${componentName} can not render "${cmsDocumentType}" documents`
                    );
                  }

                  const documentNumber = documentNumbers.get(item.ref.$ref);
                  const data = enhanceData(item, componentParams, documentNumber);

                  data.links?.forEach((link) => {
                    registerExperimentItem(
                      experimentationAgentIds,
                      isCPMEditor,
                      link.experimentationConfiguration
                    );
                  });

                  const mappedListData = applyMapData(mapData, {
                    data,
                    mappedPage,
                    componentParams,
                    pageType,
                    componentName,
                    listData: cpmListData,
                    addIssue,
                    clearIssue,
                    pageBr,
                    componentBr,
                    index,
                    length,
                    cmsDocumentType,
                    isCPMEditor,
                    addCampaignCodeToUrl,
                    //eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } as any as CpmMappingFunctionInput<Component>);

                  registerExperimentItem(
                    experimentationAgentIds,
                    isCPMEditor,
                    mappedListData.experimentationConfiguration
                  );

                  return mappedListData;
                },
                isCPMEditor,
                errorAccumulator
              )
            )
            .filter((x: Output | null): x is Output => !!x)
            .reduce((keyItems, item, index, items) => {
              const isUntranslated = isItemUntranslated(item);

              const key = [
                'cpm-child-',
                index.toString().padStart(Math.ceil(Math.log10(items.length)) + 1, '0'),
                isUntranslated ? '-noTx' : '',
              ].join('');

              keyItems[key] = item;

              return keyItems;
            }, {} as Record<string, Output>);

          if (errorAccumulator.length) {
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw errorAccumulator;
          }

          return {
            componentParams,
            pageType,
            componentName,
            listData: cpmListData,
            keyedItems,
            mappedPage,
            experimentationAgentIds,
            mappingKind: componentSchema.mappingKind,
            maintainUnsegmentedCount: componentSchema.maintainUnsegmentedCount,
          } as unknown as CpmMappedComponentData<Component, Output>;
        },
        isCPMEditor
      );
    }

    case 'DataDocument': {
      const cmsData =
        getDocumentOfTypeFromPageAndComponent<CmsDataTypesIntersection>(
          null,
          componentBr,
          pageBr
        )?.[0] ?? null;

      return handleError<CpmMappedComponentData<Component, Output>>(
        {
          componentParams,
          pageType,
          componentName,
          data: null,
          mappedPage,
          isCPMEditor,
        } as unknown as CpmMappedComponentData<Component, Output>,
        () => {
          if (cmsData === null) {
            throw new Error('can not useCpmData when useData is null');
          }

          const cmsDocumentType = cmsData.contentType.replace('dxcms:', '');

          if (!(componentSchema.cmsDocumentTypes as readonly string[]).includes(cmsDocumentType)) {
            throw new Error(`${componentName} can not render "${cmsDocumentType}" documents`);
          }

          const enhancedData = enhanceData(cmsData, componentParams);

          enhancedData.links?.forEach((link) => {
            registerExperimentItem(
              experimentationAgentIds,
              isCPMEditor,
              link.experimentationConfiguration
            );
          });

          const mappedDocumentData = applyMapData(mapData, {
            data: enhancedData,
            mappedPage,
            componentParams,
            componentName,
            addIssue,
            clearIssue,
            pageBr,
            componentBr,
            cmsDocumentType,
            isCPMEditor,
            addCampaignCodeToUrl,
          } as CpmMappingFunctionInput<Component>);

          registerExperimentItem(
            experimentationAgentIds,
            isCPMEditor,
            mappedDocumentData.experimentationConfiguration
          );

          return {
            componentParams,
            pageType,
            componentName,
            mappedPage,
            experimentationAgentIds,
            data: mappedDocumentData,
            mappingKind: componentSchema.mappingKind,
            maintainUnsegmentedCount: componentSchema.maintainUnsegmentedCount,
          } as unknown as CpmMappedComponentData<Component, Output>;
        },
        isCPMEditor
      );
    }
  }
}
